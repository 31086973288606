import $ from 'jquery';
import Pubsub from 'pubsub';
import CartInput from '../components/cart_input';
import config from '../config/config';
import Loading from '../components/loading';
import TrackingHelper from '../utils/trackinghelper';
import Html5Validation from '../utils/html5validation';
import scrollToElement from '../utils/scrollTo';
import '../utils/tabstocollapse';
import '../utils/zoom';
import '../utils/slidercontrols';
import '../utils/sliderlazyload';
import '../utils/form_elements/plusminus';
import 'swipe';
import 'ratings';

export default function Ads() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('reminder.attach', $.proxy(this.attach, this));
  Pubsub.subscribe('res', $.proxy(this.responsive, this));

  this.cart_input = new CartInput();
  this.notepad_input = new CartInput(".notepad-header");
  this.loading = new Loading();
}

Ads.prototype = {

  attach: function(msg, element) {
    var _this = this;

    var html5validation = new Html5Validation();
    html5validation.addValidationHandler();
    html5validation.validateOnSubmit();

    this.initGallery();

    if (element == document || $(element).hasClass("art-detail-page-top")) {
      //Check if zoom images are big enough..
      window.s7jsonResponse = function(data, reqId) {
        if (parseInt(data['image.width']) <= 870 || parseInt(data['image.height']) <= 489) {
          $(".main-image img").eq(parseInt(reqId)).parent().addClass("too-small");
          if (parseInt(reqId) == 0) _this.thumbsCallback(0);
        }
      };
    }

    $(".nav-tabs a.eClick").on('click', function(e) {
      $('.nav-tabs li.active a').attr('data-cerberus','cross-sell-best-block');
    });

    $(document).ready(function() {
      if($('#alternatives .swipe-wrap.stoped .slide[data-index="0"]').length === 1){
        $('#alternatives .swipe-wrap.stoped .slide[data-index="0"] .product-grid-item').attr('data-cerberus','pdp-cross-sell-last-item-viewed');
      }
      if($('#lastSeen .prod-cinema-heading').length === 1){
        $('#lastSeen .prod-cinema-heading').attr('data-cerberus','cross-sell-news-title');
      }
      if($('.js-info-large .swipe-wrap.stoped .slide[data-index="0"]').length === 1){
        $('.js-info-large .swipe-wrap.stoped .slide[data-index="0"]').attr('data-cerberus','cross-sell-news-block');
      }
      if($('#alsoBought .swipe-wrap.stoped .slide[data-index="1"] .product-grid-item:first-child .caption').length === 1){
        $('#alsoBought .swipe-wrap.stoped .slide[data-index="1"] .product-grid-item:first-child .caption').attr('data-cerberus','cross-sell-news-fifth-product-elements');
      }
      if($('#sameCategory .prod-cinema-heading').length === 1){
        $('#sameCategory .prod-cinema-heading').attr('data-cerberus','cross-sell-best-title');
      }
      if($('#sameCategory .swipe-wrap.stoped .slide[data-index="0"]').length === 1){
        $('#sameCategory .swipe-wrap.stoped .slide[data-index="0"]').attr('data-cerberus','cross-sell-best-block');
      }
      if($('.single-size input[type=radio]:checked')){
        $('.single-size input[type=radio]:checked').parent().addClass("active");
      }

      const url = new URL(window.location.href);
      const param = url.searchParams.get("alternatives");
      if(param) {
        scrollToElement($('.possible-alternatives'), 500);
      }
    });

    $(".js-reviews-text-more", element).off("click").on("click", function(e) {
      e.preventDefault();
      $('.js-reviews-text').css('height','100%');
      $('.js-reviews-text').addClass('no-after');
      $(this).parent().hide();
      $('.js-reviews-text-less').parent().show();
    });

    $(".js-reviews-text-less", element).off("click").on("click", function(e) {
      e.preventDefault();
      $('.js-reviews-text').addClass('min-height');
      $('.js-reviews-text').css('height', '');
      $('.js-reviews-text').removeClass('no-after');
      $(this).parent().hide();
      $('.js-reviews-text-more').parent().show();
      scrollToElement($('.reviews'), 500);
    });

    $(".js-article-info-text-more", element).off("click").on("click", function(e) {
      e.preventDefault();
      $('.js-article-info-text').css('height','100%');
      $('.js-article-info-text').addClass('no-after');
      $(this).parent().hide();
      $('.js-article-info-text-less').parent().show();
    });

    $(".js-article-info-text-less", element).off("click").on("click", function(e) {
      e.preventDefault();
      $('.js-article-info-text').css('height','200px');
      $('.js-article-info-text').removeClass('no-after');
      $(this).parent().hide();
      $('.js-article-info-text-more').parent().show();
    });

    $(".js-long-desc-more", element).off("click").on("click", function(e) {
      e.preventDefault();
      scrollToElement($('.article-information'), 500);
    });

    // customer review guidelines
    $("#openReviewGuidelines").off("click").on("click", function(e) {
      e.preventDefault();
      $('#the-modal').html($(".js-review-guideline").html());
      $('#the-modal').modal('show');
    });

    $("#openReviewHint").off("click").on("click", function(e) {
      e.preventDefault();
      $('#the-modal').html($(".js-review-hint").html());
      $('#the-modal').modal('show');
    });

    // product reminder
    $("#open_product_reminder").off("click").on("click", function(e) {
      e.preventDefault();
      $("#the-modal").empty().modal().load(config.productReminderModalPath, function() {
        _this.showForm(_this);
        Pubsub.publish("reminder.attach", $("#the-modal"));
      });
    });

    $("#submitProductReminder").off("click").on("click", function(e) {
      e.preventDefault();
      var form = $("#productReminderForm");
      var errors = html5validation.validateOnAjaxSubmit(form);
      if (!errors){
        _this.submitForm(_this);
      }
    });

    // create customer review
    $("[href=#openCustomerCreateReview]").off("click").on("click", function(e) {
      e.preventDefault();
      _this.openReviews();
      $('#collapseRating').collapse('show');
    });

    $(".js-scroll-to-reviews").off("click").on("click", function(e) {
      e.preventDefault();
      scrollToElement($('.review-scroll'), 500);
    });

    $(".js-scroll-to-alternatives").off("click").on("click", function(e) {
      e.preventDefault();
      scrollToElement($('.possible-alternatives'), 500);
    });

    // show customer reviews
    $("#openCustomerShowReviews").off("click").on("click", function(e) {
      e.preventDefault();
      _this.openReviews();
    });

    //show next reviews
    $(".js-show-more-reviews").off("click").on("click", function(e) {
      e.preventDefault();
      var _this = this;
      var countMore = $(this).data('count-more');
      $('.js-single-review:hidden:lt(' + countMore + ')').fadeIn(function(){
        if ($('.js-single-review:hidden').length === 0) {
         $(".js-show-more-reviews").addClass("hidden");
         $(".js-show-less-reviews").removeClass("hidden");
        }
      });
    });

    $(".js-show-less-reviews").off("click").on("click", function(e) {
      e.preventDefault();
      var _this = this;
      var reviewCount = $(this).data('review-count') - 2;
      $('.js-single-review:visible:eq(' + reviewCount + ')').fadeIn(function(){
        if ($('.js-single-review:visible').length > 4){
          $(".js-show-less-reviews").addClass("hidden");
          $(".js-show-more-reviews").removeClass("hidden");
          $('.js-reviews-text > .single-review:nth-last-child(-n+' + reviewCount + ')').css('display', 'none');
          scrollToElement($('.review-scroll'), 500);
        }
      });
    });

    $('#header-basket').off().on('click', '.js-close-mobile-flyout', function(e){
      e.preventDefault();
      $(this).parents('.js-mobile-add-to-cart').remove();
    });

    //open right tab/panel
    $(".links a[href=#more-infos]").off("click").on("click", function() {
      $(".panel-heading").not(".panel-in").find("a[href=#service-pane]:visible").click();
      $(".panel-heading.panel-in").find("a[href=#detail-pane]:visible").click();
      $("a[href=#service]:visible").click();
    });
    $("a.more-infos").off("click").on("click", function() {
      $(".panel-heading").not(".panel-in").find("a[href=#detail-pane]:visible").click();
      //$(".panel-heading.panel-in").find("a[href=#service-pane]:visible").click();
      $("a[href=#detail]:visible").click();
    });

    //put it on the notepad
    $(".notepad-link").off("click").on("click", function(e) {
      e.preventDefault();
      $.get($(this).attr("href"), function(data) {
        _this.notepad_input.input(data.markup, data.count);
      }).fail(function(jqXhr, error) {
        if (window.console) console.log("Notepad input error!", error);
      });
    });

    $(".reminder-link").off("click").on("click", function(e) {
      e.preventDefault();
      $.get($(this).attr("href") + "&email=" + $("<div/>").text($("#reminder_email").val()).html(), function(data) {
        $("#reminder_callback").html(data.markup);
      }).fail(function(jqXhr, error) {
        if (window.console) console.log("Reminder input error!", error);
      });
    });

    var initPanelSliders = $.proxy(this.initPanelSliders, this);
    $(".art-detail-page .nav-tabs li a", element).off("click").on("click", function(e) {
      e.preventDefault();
      $(this).tab('show');
      initPanelSliders();
    });
    if (element == document) $(".first-tabs .nav-tabs a:first, .second-tabs .nav-tabs a:first").tab('show');

    $(".art-detail-page-slider-tabs .panel-collapse").off("show.bs.collapse.sliders").on("show.bs.collapse.sliders", initPanelSliders);

    $(".order-count", element).plusminus();

    $("#article-form", element).off("submit").on("submit", function() {
      $(".js-add-to-cart", element).click();
      return false;
    });

    $(".js-add-to-cart", element).off("click").on("click", $.proxy(this.submit, this));

    $(".product-review-form").off("click").on("click", ".js-submit-review", function(e){
      e.preventDefault();
      _this.submitReview();
    });

    $('#productQuantity').off("change").on('change', function(e) {
      e.preventDefault();
      var data = $("#article-form").serialize();

      $.post(config.amountChangePath, data)
        .done(function(result) {
          if(result.success){
            var newContent = $(result.markup);
            $('.price-info').replaceWith(newContent);
          }
        });
    });

    //Select Boxes change
    $("[data-update]").off("change").on("change", function(e) {
      _this.loading.load();
      var trackinghelper = new TrackingHelper();
      var $element = $(e.target);
      var category = $element.data('category');
      var action = $element.data('action');
      var value = $element.data('value');
      trackinghelper.event(category !== undefined ? category : '', action, value !== undefined ? value : '');

      $.get(config.variationChangePath + "?q=ads_top&" + $("#article-form").serialize(), function(jsonData) {
        _this.loading.stop();
        _this.update(jsonData);
        location.replace("#itemId=" + $("input[name=itemId]").val());
        $("label[for='" + $element.attr('id') + "']").addClass('active');
        if(_this.last_msg == 'res.xs'){
          Pubsub.publish("res.xs", $(".recommendation"));
        } else {
          Pubsub.publish("res", $(".recommendation"));
        }
      }).fail(function(jqXhr, e) {
        if (window.console) console.log(e);
      });
    });

    $(".js-helpful").off("click").on("click", function(e) {
      var _this = this;
      var value = $(this).data('value');
      var prodId = $("input[name=productId]").val();
      var revId = $(this).parents('.reviews').find("input[name=reviewId]").val();
      var data = {
          "ratingid": revId,
          "productNo": prodId,
          "wasHelpful": value,
       }
      $.post(config.productReviewHelpfulUrl, data).done(
         function(resultJson) {
           if(resultJson.success) {
             $(_this).parent().find('.js-thank-you').show();
             $(_this).parent().find('.js-thank-you').html(resultJson.markup);
             $(_this).parent().find('.js-helpful').hide();
           }
           else {
             console.error('AJAX failed');
           }
         }
         ).fail(function(jqXHR, textStatus, errorThrown) {
           console.error('AJAX failed', jqXHR, textStatus, errorThrown);
           });
      });

  },

  showForm: function(_this) {
    var itemId = $("input[name=itemId]").val();
    var size = $('.js-size option[selected]').val();
    $.get(config.productReminderFormPath + "?itemId=" + itemId + "&size=" + size, function(data) {
      $("#product-reminder-anchor").empty().html(data);
      Pubsub.publish("reminder.attach", $("#product-reminder-anchor"));
    });
  },

  submitForm: function(_this) {
    var $form = $("form#productReminderForm");
    var itemId = $("input[name=itemId]").val();
    $.post(config.productReminderSubmitPath + "?itemId="+ itemId, $form.serialize(), function(data) {
      $("#product-reminder-form-anchor").empty().html(data);
      Pubsub.publish("reminder.attach", $("#product-reminder-form-anchor"));
    });
  },

  determineVariation: function(forwardPath, initialItemId) {
    "use strict";
    var _this = this;

    var hash = window.location.hash,
      itemIdString = "itemId=",
      itemIdStart = hash.substring(hash.indexOf(itemIdString) + itemIdString.length),
      indexOfAnd = itemIdStart.indexOf("&"),
      itemId = indexOfAnd > 0 ? itemIdStart.substring(0, indexOfAnd) : itemIdStart;
    var catalogIndex = $("input[name=catalogIndex]").val();
    var catalogIndexParam  = "";
    if(catalogIndex != null && catalogIndex != 'undefined') {
      catalogIndexParam = "&catalogIndex=" + catalogIndex;
    }

    if (itemId !== "undefined" && itemId !== "" && itemId !== initialItemId) {
      $.get(forwardPath + "&itemId=" + itemId + "&previousId=" + initialItemId + catalogIndexParam, function(jsonData) {
        _this.loading.stop();
        _this.update(jsonData);
      }).fail(function(jxXhr, e) {
        if (window.console) console.log(e);
      });
    } else {
      $(_this.cssShowGalleryClasses).show();
    }
  },
  update: function(jsonData) {
    var $htmlData = $(jsonData.markupTop);
    $(".popover").remove();
    $(".swipe", $htmlData).css({
      visibility: "hidden"
    });

    $(".art-detail-page-top").replaceWith($htmlData);
    var updateTitle = $(".art-detail-page-top.col-container").data("update-title");
    $('head title').text(updateTitle);
    $(".markup_features_tab").replaceWith($(jsonData.markupFeatures));
    this.gallery_swipe = false;

    $("#service-pane .panel-body").empty().html(jsonData.markupBottom);
    if (jsonData.markupBottom == "") {
      //Hide Service
      $("#service, [href=#service]").css({
        visibility: "hidden"
      });
      //Activate Detail Pane
      $(".panel-heading").not(".panel-in").find("a[href=#detail-pane]:visible").click();
      $("a[href=#detail]:visible").click();
    }
    else $("#service, [href=#service]").css({
        visibility: "visible"
      });

    $("#alternative").empty().html(jsonData.possibleAlternatives);
    if (jsonData.possibleAlternatives == "") {
      //Hide Alternatives
      $("#alternative, [href=#alternative]").css({
        visibility: "hidden"
      });
      //Activate Detail Pane
      $(".nav-tabs li").not(".active").find("a[href=#from-this]:visible").click();
      $("a[href=#from-this]:visible").click();
    }
    else $("#alternative, [href=#alternative]").css({
        visibility: "visible"
      });

    Pubsub.publish("core.attach", $(".art-detail-page-top"));
  },
  initGallery: function() {
    var _this = this;

    var makeGallery = function() {
      this.gallery_swipe = $(".main-swipe").Swipe({
        callback: this.thumbsCallback,
        continuous: false,
        startSlide: config.activeADSImage
      }).knobs({
        classes: ["hidden-md", "hidden-lg"]
      }).data("Swipe");
      this.responsive(this.last_msg, true);
    };

    if (!this.gallery_swipe) {
      this.gallery_swipe = true; //async loading, do not call me again.
      makeGallery.call(_this);
    }
  },

  setVoting: function(val, e) {
    $('#votevalue').val(val);
    return false;
  },

  submitReview: function() {

    $("#reviews-pane #product-review-form .btn-submit").off("click");

    $("#reviews-pane #product-review-form .btn-submit").addClass('loader');

    $.post($("#product-review-form").attr("action"), $("#product-review-form").serialize(), function(data) {
      if(data.success == "true") {
        $("#reviews-pane .review-tab").empty().html(data.markup);
        scrollToElement($('.review-scroll'), 500);
      }else{
        $("#reviews-pane .review-tab .js-product-review-form").empty().html(data.errors);
        $('input.rating').rating();
        if($('.has-error').is(':visible')) {
          scrollToElement($('.has-error:first'), 500);
        }
      }
      Pubsub.publish("core.attach", $("#product-review-form"));
    }).fail(function(jqXhr, e) {
      if (window.console) console.log(e);
    }, "html")
    .always(function() {
      $("#reviews-pane #product-review-form .btn-submit").removeClass('loader');
    });
  },

  submit: function(e) {
    e.preventDefault();
    var _this = this;
    $.post($("#article-form").attr("action"), $("#article-form").serialize(), function(data) {
      if(data.success){
        _this.cart_input.input(data.markup, data.count, data.total, null, null);
        if(_this.last_msg=='res.xs'){
          $('#header-basket').append(data.mobileMarkup);
          window.setTimeout(function() {
            $('#header-basket').find('.js-mobile-add-to-cart').remove();
          },10000);
        } else if(_this.last_msg=='res.sm' || _this.last_msg=='res.md') {
          $('#the-modal').html(data.markup);
          $('#the-modal').on('shown.bs.modal', function (e) {
            var modalSwipe = $(this).find(".swipe");
            if (modalSwipe.data("Swipe")) modalSwipe.data("Swipe").kill();
            modalSwipe.Swipe({
              pair: 3,
              continuous: false
            }).controls().lazy();
          });
          $('#the-modal').modal('show').trigger('shown');
        } else {
          $('#the-modal').html(data.markup);
          $('#the-modal').on('shown.bs.modal', function (e) {
            var modalSwipe = $(this).find(".swipe");
            if (modalSwipe.data("Swipe")) modalSwipe.data("Swipe").kill();
            modalSwipe.Swipe({
              pair: 4,
              continuous: false
            }).controls().lazy();
            if($('.modal-content .cart-teaser .prod-cinema-heading').length === 1){
              $('.modal-content .cart-teaser .prod-cinema-heading').attr('data-cerberus','pdp-atb-popin-cross-sell-title');
            }
            if($('.modal-content .cart-teaser .swipe-wrap.stoped .product-grid-item:first-child').length === 1){
              $('.modal-content .cart-teaser .swipe-wrap.stoped .product-grid-item:first-child').attr('data-cerberus','pdp-atb-popin-cross-sell-first-product-block');
            }
            if($('.modal-content .cart-teaser .swipe-wrap.stoped .product-grid-item:first-child').length === 1){
              $('.modal-content .cart-teaser .swipe-wrap.stoped .product-grid-item:first-child .thumbnail').attr('data-cerberus','pdp-atb-popin-cross-sell-first-product-elements');
            }
          });
          $('#the-modal').modal('show').trigger('shown');
        }
      }else{
        var selector = $('.js-ptext-error');
        scrollToElement(selector,500);
        selector.html(data.errorsMarkup);
        $('.js-input-ptext').each(function(i, obj){
          var _this = this;
          if($(_this).val().length > $(_this).data('maxlength')){
            $(_this).addClass('has-error');
            selector.css("padding-bottom","15px");
          }
        });
      }
    }).fail(function(jqXhr, e) {
      if (window.console) console.log(e);
    });

  },
  responsive: function(msg, on_off) {
    if (on_off) {
      this.last_msg = msg;
      this.removeSwipes();
    }

    switch (msg) {
    case 'res.xs':

      if (on_off) { // entered state xs

        //hide all tabs
        $(".art-detail-page-tabs, .art-detail-page-slider-tabs").ttcol({
          collapse: false
        });

        //show only reviews xs
        var el = $(".art-detail-page-slider-tabs #reviews");
        el.addClass("ttcol-processed");
        el.find(".panel-collapse").removeClass("in");
        el.find(".panel-collapse").addClass("collapse");
        el.removeClass("tab-pane");

        $(".thumb-swipe").Swipe({
          pair: 4,
          continuous: false
        }).knobs();


        // Add controls to 3Pagen modern with legacy breakpoint sm
        const mainSwipe = $('.main-swipe');
        if(mainSwipe.closest('.x-style')){
          mainSwipe.Swipe().controls();
        }

        $(".last-seen-swipe").Swipe().knobs().lazy();
      } else { // left state xs

      }
      break;
    case 'res.sm':
      if (on_off) { // entered state sm
        var _this = this;

        $(".last-seen-swipe").Swipe({
          pair: 4
        }).knobs().lazy();

        $(".art-detail-page-tabs, .art-detail-page-slider-tabs").ttcol({
          collapse: false
        });

        $(".thumb-swipe").Swipe({
          pair: 4,
          continuous: false
        }).knobs();
        this.addThumbsClick();
      } else { // left state sm

      }
      break;
    default:
      if (on_off) {

        $(".art-detail-page-tabs, .art-detail-page-slider-tabs").ttcol({
          collapse: false
        });

        $(".thumb-swipe").Swipe({
          pair: 4,
          continuous: false,
          transitionEnd: function(index, elem) {
            //Tracking
            var trackinghelper = new TrackingHelper();
            var $element = $(elem).parents('.swipe').next();
            var category = $element.data('category');
            var action = $element.data('action');
            var value = $element.data('value');
            trackinghelper.event(category !== undefined ? category : '', action, value !== undefined ? value : '');
          }
        }).controls();

        this.addThumbsClick();
        $(".last-seen-swipe").Swipe({
          pair: 6,
          continuous: false
        }).controls().lazy();

      }
    }
    if (on_off) {
      this.initPanelSliders();
      Pubsub.publish("core.attach", $(".last-seen-swipe"));
    }

    this.zoom = $(".main-image div.pda-big-img-view").zoom().data('Zoom');
  },
  addThumbsClick: function() {
    var _this = this;
    $(".thumbs .gallery-thumb").off("click").on("click", function() {
      _this.gallery_swipe.slide($(".thumbs .gallery-thumb").index($(this)));
      $(".thumbs .gallery-thumb").removeClass("active");
      $(this).addClass("active");
    });
  },
  thumbsCallback: function(pos) {
    setTimeout(function() {
      var thumb_swipe = $(".thumb-swipe").data("Swipe");
      if (thumb_swipe !== undefined) {
        thumb_swipe.slide(Math.floor(pos / 4));
      }
    }, 0);
    $(".thumbs .gallery-thumb").removeClass("active").eq(pos).addClass("active");
  },
  _initTabSwipes: function(res) {
    var swipes = $(".art-detail-page-slider-tabs .swipe");
    swipes.each(function() {
      if ($(this).data("Swipe")) $(this).data("Swipe").kill();
    });
    if (res == "res.sm") swipes.Swipe({
        pair: 3
      }).knobs();
    else swipes.Swipe({
        pair: 4,
        continuous: false
      }).controls().lazy();
  },
  initPanelSliders: function(e) {
    var _this = this;
    setTimeout(function() {
      _this._initTabSwipes(_this.last_msg);
      Pubsub.publish("core.attach", $(".art-detail-page-slider-tabs .swipe"));
    },
      0);
  },
  removeSwipes: function() {
    if (this.zoom) this.zoom.kill();

    $(".thumbs .gallery-thumb").off("click");

    $(".swipe").each(function() {
      if ($(this).hasClass("main-swipe")) return true;

      if ($(this).data("Swipe")) $(this).data("Swipe").kill();
    });
  },
  pinchStart: function() {
    $(".swipe").css("-ms-touch-action", "none");
  },
  pinchEnd: function() {
    $(".swipe").css("-ms-touch-action", "pan-y");
  },
  openReviews: function() {
    var _this = this;
    var reviews;
    if(_this.last_msg == 'res.xs'){
      reviews = $("[href=#reviews-pane]");
    } else {
      reviews = $("[href=#reviews]");
    }
    $('html,body').animate({scrollTop: reviews.offset().top});
    if (!$(".js-btn-review").is(":visible")) {
      reviews.click();
    }
  },
};
