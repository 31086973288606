import $ from 'jquery';
import mask from 'mask';
import Pubsub from 'pubsub';
import forms from '../utils/forms';
import Html5Validation from '../utils/html5validation';
import Loading from '../components/loading';
import config from '../config/config';
import numberformats from '../utils/numberformats';
import Coupon from '../components/coupon';
import AsyncCheckout from '../views/async_checkout';
import scrollToElement from "../utils/scrollTo";
import showOrHidePassword from "../components/password";
import AddressSuggestions from '../components/address_sugesstions';

export default function Checkout() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  this.loading = new Loading();
  this.coupon = new Coupon();
  AsyncCheckout();
}

Checkout.prototype = {

  attach: function(msg, element) {
    var _this = this;
    var html5validation = new Html5Validation();

    var addressSuggestions;
    if ($(".js-address-suggestions").length != 0) {
      addressSuggestions = new AddressSuggestions();
    }

    // Login: Let opportunities toggle so only one field is shown at the time
    $(".checkout-login article.content > .toggleButton > button").off("click").click(function() {
      $(".checkout-login article.content").toggleClass("folded", 200, "swing");
    });

    $("a.btn-navi-step .checkout-navi-step").each(function() {
      if ($("a.btn-navi-step .checkout-navi-step").hasClass("ready")) {
        $("a.btn-navi-step .checkout-navi-step.ready span.number").hide();
        $("a.btn-navi-step .checkout-navi-step.ready span.glyphicon-3P-checked").show();
      }
    });

    $(".voucher-toggle").off("click").click(function() {
      $(this).toggleClass("toggled");
      $(this).parent().parent().find(".voucher-input").toggleClass("hidden");
    });

    // Voucher code - add
    const voucherFrom = $('.js-voucher-code-form');
    voucherFrom.off("submit").on("submit", function(e){
      e.preventDefault();

      const formData = voucherFrom.serialize();
      $(".js-vouchercode-message").html("");
      $.post(config.voucherCodeUrl, formData)
        .done(function(data) {
          if(data.success) {
            window.location.href = window.location.href;
          } else {
            $(".js-vouchercode-message").html(data.error);
            $(".js-vouchercode-message").css("display", "block");
            $("input[name='voucherCode']").addClass("has-error");
          }
        }).fail(function(error) {
        if (window.console) console.error("Failed to add voucher code!", error);
      });
    });

    // Voucher code - remove
    const voucherRemoveFrom = $('.js-voucher-code-form-remove');
    voucherRemoveFrom.off("submit").on("submit", function(e) {
      e.preventDefault();
      $.post(config.voucherCodeRemoveUrl)
        .done(function(data) {
          if(data.success) {
            window.location.href = window.location.href;
          }
        }).fail(function(error) {
        if (window.console) console.error("Failed to remove voucher code!", error);
      });
    });

    showOrHidePassword();

    html5validation.addValidationHandler();
    html5validation.validateOnSubmit();

    $("[data-date]", element).mask("99.99.9999");

    if ($(".checkout-address").length !== 0) {
      // Addresses
      $("#packstation-checkbox").off("click").click(function() {
        var isChecked = $(this).is(':checked');
        $("#address-form-packstation").toggle(isChecked);
        $("#address-form-default").toggle(!isChecked);
      });
    }

    if ($(".checkout-shipping").length == 0) return;
    // Shipping
    // Submit new address form
    $("form#new-shipping-addr button", element).off("click").on("click", function(e) {
      e.preventDefault();
      var $form = $("form#new-shipping-addr");
      var validator = new forms.Validator($form);
      if (validator.validate(true)) {
        _this.loading.load();
        $.post($form.attr("action") + "?q=new_shippingaddr", $form.serialize(), function(data) {
          if (data.success) {
            var radio = $("#deliver-radios .radio").first().clone();
            $("#deliver-radios .radio input").prop("checked", false);
            radio.find("span").html(data.name);
            radio.find("input").prop("checked", true).val(data.id);

            $("#deliver-radios").append(radio);
            $("#new_shipping_address").collapse("hide");

            Pubsub.publish("core.attach", $("#deliver-radios"));
          } else {
            $("#new_shipping_address").empty().append(data.markup);
            $form = $("form#new-shipping-addr");
            validator = new forms.Validator($form);
            validator.showError(true);
            Pubsub.publish("core.attach", $("#new_shipping_address"));
          }
          _this.loading.stop();
        }).fail(function(jqXhr, e) {
          if (window.console) console.log(e);
        });
      }
    });

    $(".js-alert-form").on("keydown",function(){
      $('.js-alert').hide();
    });

    $("span.button-checkout-secondary.js-scroll-new-customer").off("click").on("click", function(e) {
      e.preventDefault();
      scrollToElement($(".new-customer-scroll"), 500);
    });

    // call carrier service Pickup points
    $('.js-carrier-service').off('click').on('click', function () {
      _this.callCarrierService();
    });

    $( document ).ready(function() {
      if( $('#js-pr-radio').prop('checked') ) {
        _this.callCarrierService();
      }
    });

    // search pickup points
    $('.js-pointrelais').off("click").on("click", '.js-pr-search', function(e){
      e.preventDefault();
      $('.js-pr-search').addClass('loader');
      var zipCode = $('.js-pr-zipcode').val();
      $.post(config.carrierService + "?zipCode=" + zipCode + "&userPickkUpPoint=false", function(data) {
        $('.js-pr-city').html(data.markup);
        $('.js-pr-search').removeClass('loader');
        $('input[name=indexOfSelectedPickUpPoint]').first().prop( "checked", true ).change();
      }).fail(function(jqXhr, error) {
        $('.js-pr-search').removeClass('loader');
        if (window.console) console.log("load pickup point addresses error!", error);
      });
    });

    // set pickUpPoint address from selected radio button
    $('.js-pointrelais').off('change').on('change', '.js-filter-city', function(e){
      var city = $(this).val();
      if( city == 0) {
        $('.js-pup-list label').parent().show();
      }else {
        $('.js-pup-list label').parent().hide();
        $('.js-pup-list input[data-city="'+ city +'"]').parent().parent().parent().show();
      }
    });

    $(".js-pointrelais").off('keydown').on('keydown', '.js-pr-zipcode', function(e) {
      var keyCode = e.which;
      if (e.type == "keydown" && keyCode != 13 && keyCode != 9) {
        return;
      }
      e.preventDefault();
      $('.js-pup-btn').click();
    });

    $('#error').affix({
    });

    $("#saferpay-button", element).off("click").on("click", function(e) {
      $.get(cfg.saferpayPaymentPageInitializeUrl, function(data) {
        window.location.href = data.url;
      });
    });

    // Load and attach form
    $(".new-shipping-btn").off("click").on("click", function() {
      var shipping = $("#new_shipping_address");
      if (shipping.hasClass("in")) {
        shipping.collapse("hide");
        return;
      }
      // Load edit form
      _this.loading.load();
      $.get(config.newAddressPath + "?q=shipping_newadd", function(data) {
        shipping.empty().append(data);

        shipping.bind("hidden.bs.collapse", function() {
          $(this).empty();
        });
        shipping.collapse().collapse("show");

        _this.loading.stop();
        // Attach event handlers
        Pubsub.publish("core.attach", shipping);
      }).fail(function(jqXhr, e) {
        if (window.console) console.log(e);
      });
    });

    // update addressbook id
    $(".address-book").off("click").on("click", function() {
      $("#address-id").val($(this).attr("data-value"));
    });

    // TODO: harmonize/standardize following methods regarding address changes

    // Load change address form on shipping page
    $(".checkout-shipping .js-changeAddress").off("click").on("click", function(e){
      e.preventDefault();

      var address_type = $(this).data('type');
      var url = '';

      if( address_type == 'shipping' ) {
        url = config.checkoutShippingNewAddressChangeUrl;
      } else if(address_type == 'invoice') {
        url = config.checkoutInvoiceAddressChangeUrl;
      }

      $.get(url, function(data) {
        var checkoutShipping = $(".checkout-shipping");
        checkoutShipping.html(data.markup);
        Pubsub.publish("core.attach", checkoutShipping);
      }).fail(function(jqXhr, error) {
        if (window.console) console.log("changeAddress error!", error);
      });
    });

    $('.checkout-shipping .js-new-invoice-address').off("click").on("click", function(e){
      e.preventDefault();
      addressSuggestions.submitFormInvoice($(".js-address-form"));
    });

    // Submit existing address via AJAX
    $(".checkout-shipping .js-submitRecentDeliveryAddressButton").off("click").on("click", function(e){
      e.preventDefault();
      var form = $(this).parent().serialize();
      $.post(config.checkoutShippingExistingAddressChangeUrl, form, function(data) {
        if(data.success) {
          window.location.reload();
        } else {
          var checkoutShipping = $(".checkout-shipping");
          checkoutShipping.html(data.markup);
          Pubsub.publish("core.attach", checkoutShipping);
        }
      }).fail(function(jqXhr, error) {
        if (window.console) console.log("submitRecentDeliveryAddressButton error!", error);
      });
    });

    $(".checkout-shipping .js-submitNewAddressButton").off("click").on("click", function(e){
      e.preventDefault();
      var isValid = html5validation.validate($('#js-newAddressForm'));
      var form;
      if (isValid) {
        form = $('#js-newAddressForm input,#js-newAddressForm select').filter(function(e) {
          if ($(this).val().length > 0) {
            return $(this).val();
          }});
      } else {
        return;
      }
      addressSuggestions.submitFormShipping(form);
    });

    $('#collapseOne').on('shown.bs.collapse', function () {
      $('.js-submit-check').attr("disabled", false);
    })
    $('#collapseTwo').on('shown.bs.collapse', function () {
      if ($('.js-country-msg').is(":visible")) {
        $('.js-submit-check').attr("disabled",true);
      }else{
        $('.js-submit-check').attr("disabled", false);
      }
    })
  },
  callCarrierService: function() {
    var zipCode = $('#js-pr-radio').data('userzipcode');
    var index = $('#js-pr-radio').data('selectedindex');
    var alreadyOpened = $('#collapseOne').data('was-opened');
    if (!alreadyOpened) {
      $('#collapseOne').data('was-opened',true);
      if(!index){
        $('.panel-default:first-of-type').addClass('loader');
        $.post(config.carrierService + "?zipCode=" + zipCode + "&userPickkUpPoint=true", function(data) {
          $('.point-relais').html(data.markup);
        }).fail(function(jqXhr, error) {
          if (window.console) console.log("load pickup point addresses error!", error);
        }).done(function() {
          $('input[name=indexOfSelectedPickUpPoint]').first().click();
        }).always(function(){
          $('.panel-default:first-of-type').removeClass('loader');
        });
     }
    }
  },
  checkOptions: function(element) {
    var addressValue = $("[name=addressType]:checked").val();
    var paymentValue = $("[name=paymentType]:checked").val();
    var deliveryValue = $("[name=deliveryType]:checked").val();

    if (addressValue != "INVOICE")
      addressValue = "DELIVERY";

    if (this.options != undefined) {
      var delivery_types = this.options.addressType[addressValue].deliveryType;
      delivery_types = this.arrayIntersect(delivery_types, this.options.paymentType[paymentValue].deliveryType);
      this.disableInputs(".delivery-panel .delivery-options input[type=radio]", delivery_types);

      var payment_types = this.options.addressType[addressValue].paymentType;
      payment_types = this.arrayIntersect(payment_types, this.options.deliveryType[deliveryValue].paymentType);
      this.disableInputs("form .panel-heading input[type=radio]", payment_types);

      var address_types = this.options.paymentType[paymentValue].addressType;
      address_types = this.arrayIntersect(address_types, this.options.deliveryType[deliveryValue].addressType);
      this.disableInputs(".address-block input[type=radio]", address_types, true);
    }
  },
  disableInputs: function(selector, valid_array, isAddress) {
    var _this = this;
    $(selector).each(function() {
      if ($.inArray($(this).val(), valid_array) > -1) _this.setInputDisabled($(this), false); else {
        _this.setInputDisabled($(this), true);

        // Special Case for address
        if (isAddress && $.inArray("DELIVERY", valid_array) > -1) {
          if ($(this).val() !== "INVOICE") _this.setInputDisabled($(this), false);
        }
        // Invalid combination handling
        if ($(this).prop("checked") && $(this).prop("disabled")) {
          $("[name=" + $(this).attr("name") + "]").not(":disabled").first().prop("checked", true).click().trigger("change");
          $(".invalid-combination").show();
          $("body, html").animate({
            scrollTop: $(".invalid-combination").offset().top
          }, 200);
        }
      }
    });
  },
  setInputDisabled: function($input, disabled) {
    if (!disabled) {
      $input.attr("disabled", null);
      $input.parent().parent().removeClass("disabled");
    } else {
      $input.attr("disabled", "disabled");
      $input.parent().parent().addClass("disabled");
    }
  },
  arrayIntersect: function(a1, a2) {
    return $.map(a1, function(a) {
      return $.inArray(a, a2) < 0 ? null : a;
    });
  }
};


