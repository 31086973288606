import $ from 'jquery';
import Pubsub from 'pubsub';
import Loading from '../components/loading';

export default function InitFilterReset() {
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  this.loading = new Loading();
}

function resetPriceFilters() {
  $(".js-single-checked").each(function () {
    if ($(this).prop("checked")) {
      $(this).prop("checked", false);
    }
  });
}

function triggerPriceChange() {
  $(".js-single-checked").first().trigger("change");
}

InitFilterReset.prototype = {
  attach: function (msg, element) {
    $(".js-product-list-filters-reset [data-resettarget]").off("click").on("click", function(event) {
      $("#"+ event.target.dataset.resettarget).click();
    });

    $(".js-product-list-filters-reset [data-resetprice]").off("click").on("click", function() {
      resetPriceFilters();
      triggerPriceChange();
    });

    $(".js-single-checked").off("click").on("click", function(event) {
      event.preventDefault();
      if ($(this).prop("checked")) {
        resetPriceFilters();
        $("#" + event.target.id).prop("checked", true);
      } else {
        resetPriceFilters();
      }
      triggerPriceChange();
    });
  }
};
