/**
 * Kümmert sich um die Behandlung der Filter und Sortierung auf der Produktliste. Dazu gehört auch das Neuladen der Daten.
 */
//noinspection JSHint
import $ from 'jquery';
import Pubsub from 'pubsub';
import Loading from '../components/loading';
import History from 'history';
import {updateSeoRelTags} from '../components/seotags';

/**
 * Erzeugt eine Funktion die die Daten neu lädt wenn sich die History ändert. Die History ändert sich bspw. wenn man
 * einen Filter auswählt oder wenn man von einer IE Url einspringt, welche eine # enthält.
 */
function createLoadDataOnHistoryChangeFunction(_this) {
  return function() {
    // Log the State
    var state = History.getState(); // Note: We are using History.getState() instead of event.state
    var url = state.url;
    var ajaxUrl = url + "&ajax="; // marker Parameter hinzufügen, dass wir nur den AJAX Content austauschen wollen

    $.get(ajaxUrl, function(data) {
      _this.loading.stop();
      var $data = $(data);

      Pubsub.publish("core.preattach", $data); // restore accordion positions

      setTimeout(function() {
        $(".product-list-part").replaceWith($data);
        updateSeoRelTags();

        Pubsub.publish("core.attach", $data);
        Pubsub.publish("tracking.pageViewAjaxEvent", null);

        Pubsub.publish("offcanvas.showfilter", true);
        Pubsub.publish("datafilter", true);

      }, 1); //Trick accordion animation

    }).fail(function(error) {
      _this.loading.stop();
      if (window.console) {
        //noinspection JSHint
        console.log(error);
      }
    });
  };
}

export default function ProductListFilters() {
  Pubsub.subscribe('core.preattach', $.proxy(this.preattach, this));
  Pubsub.subscribe('core.attach', $.proxy(this.attach, this));
  this.in_ids = [];
  this.loading = new Loading();

  // Bind to State Change,  Note: We are using statechange instead of popstate
  History.Adapter.bind(window, 'statechange', createLoadDataOnHistoryChangeFunction(this));
}


ProductListFilters.prototype.preattach = function(msg, element) {
  $.each(this.in_ids, function() { //restore open accordion tabs
    element.find(".product-filter-list #" + this).removeClass("collapse").addClass("in");
  });
};

function resetInputs($inputs) {
  var refresh = false;
  $inputs.each(function() {
    if ($(this).prop("checked")) {
      $(this).prop("checked", false);
      refresh = true;
    }
    if ($(this).is(":text") && $(this).val() !== $(this).attr("data-original")) {
      $(this).val($(this).attr("data-original"));
      refresh = true;
    }
  });
  if (refresh) {
    $inputs.first().trigger("change");
  }
}

ProductListFilters.prototype.attach = function(msg, element) {
  var _this = this;

  $("#from_price, #to_price").on("click", function() {
    $("input[value=range]").attr("checked", "checked");
  });

  $(".colorThumb").tooltip();

  //Filter changes
  var pageSizeHeader = '#'+ $(".filter-header select[name='pageSize']").attr('id');
  var pageSizeFooter = '#'+ $(".footer-pagination select[name='pageSize']").attr('id');
  $("form#filter-form input, #item-count", element).off("change").on("change", $.proxy(this.reload, this));

  $("input[type=radio][name=sortBy]", element).off("change").on("change", $.proxy(this.reloadSort, this));

  $(pageSizeHeader, element).off("change").on("change", $.proxy(this.reloadSort, this));
  $(pageSizeFooter, element).off("change").on("change", $.proxy(this.reloadSort, this));

  //Reset all filters
  $("form#filter-form .filter-reset, .js-reset-all-filters", element).off("click").on("click", function() {
    resetInputs($("form#filter-form input", element));
  });

  // Reset single filter
  $("form#filter-form .current-filter-reset", element).off("click").on("click", function() {
    var inputName = $(this).attr('data-input-name');
    if (typeof inputName !== "undefined") {
      resetInputs($("form#filter-form input[name='" + inputName + "']", element));
    }
  });

  var bs_events = "shown.bs.collapse hidden.bs.collapse";
  $(".product-filter-controls").off(bs_events).on(bs_events, function() {
    _this.in_ids = []; //save open accordion tabs
    $(".product-filter-list .in").each(function() {
      _this.in_ids.push($(this).attr("id"));
    });
  });

};

function getPageSizeId() {
  var pageSizeId;
  if ($(window).width() <= 768) {
    pageSizeId = '#'+ $(".footer-pagination select[name='pageSize']").attr('id');
  }else{
    pageSizeId = '#'+ $(".filter-header select[name='pageSize']").attr('id');
  }
  return pageSizeId;
};

// todo recreater sort
ProductListFilters.prototype.reloadSort = function(e) {
  var $element = $(e.target);
  var category = $element.data('category');
  var action = $element.data('action');
  // action = action !== undefined ? action.replace('~~PLACEHOLDER~~', $element.text()) : '';
  var value = $element.data('value');
  this.reload(e);
};

ProductListFilters.prototype.reload = function(e) {
  var filter_form = $("form#filter-form");
  var _this = this;
  var pageSizeId = getPageSizeId();

  var price_changed = false;

  if ($(e.target).is("[value=range]")) {
    this.enableRange(true);
    return;
  }

  if ($(e.target).is('#from_price') || $(e.target).is('#to_price')) {
    price_changed = true;
  }

  var url = filter_form.attr("action") + "&" + filter_form.serialize() + "&price_range_changed=" + price_changed + "&" + $("#item-count, input[type=radio][name=sortBy]:checked").serialize() + "&" + $(pageSizeId).serialize();
  _this.reloadUrl(url);
};

ProductListFilters.prototype.reloadUrl = function(url) {
  var _this = this;
  _this.loading.load();
  History.pushState({}, document.title, url);
};
